@import "~@/styles/variables.scss";














































































.goods-list-container {
    .goods-list-card-body {
        position: relative;
        text-align: center;
        cursor: pointer;

        .goods-list-tag-group {
            position: absolute;
            top: 10px;
            right: 5px;
            z-index: 9;
        }

        .goods-list-image-group {
            height: 400px;
            overflow: hidden;

            .goods-list-image {
                width: 100%;
                height: 400px;
                transition: all ease-in-out 0.3s;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        .goods-list-title {
            margin: 8px 0;
            font-size: 16px;
            font-weight: bold;
        }

        .goods-list-description {
            font-size: 14px;
            color: #808695;
        }

        .goods-list-price {
            margin: 8px 0;
            font-size: 14px;
            color: $base-color-orange;

            s {
                color: #c5c8ce;
            }
        }
    }
}
